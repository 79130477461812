import KEY from '../../../../../const/storeKey'
const {  ACTIONS, _POSTS } = KEY
const ACTION = ACTIONS._POSTS
export default {
    KEY_NAME: _POSTS,
    ACTION,
    namespaced: true,
    state: {
        categories: []
    },
    getters: {
        categories: state => state.categories,
    },
    mutations: {
        CHANGE_CATEGORIES(state, categories) {
            state.categories = categories;
        }
    },
    actions: {
        CHANGE_CATEGORIES({ commit }, categories) {
            commit(ACTION.CHANGE_CATEGORIES, categories);
        }
    }
}
