<template>
  <div id="form-post">
    <el-form :label-position="'left'" label-width="100px" :model="form">
      <el-form-item :label="lang.title">
        <el-input v-model="form.title" :placeholder="lang.typeTitle"></el-input>
      </el-form-item>
      <el-form-item :label="lang.category">
        <div v-if="!isNewCategory" style="display:flex; align-items: center">
          <el-select v-model="form.category" filterable default-first-option :placeholder="lang.selectCategory">
            <el-option v-for="i in categories" :key="i._id" :label="i.name" :value="i._id"></el-option>
          </el-select>
          <el-button type="primary" size="medium" @click="isNewCategory = true" style="margin: 0 0 0 8px" plain>
            <i class="el-icon-plus"></i>
          </el-button>
        </div>
        <div v-else>
          <el-input v-model="newCategory" :placeholder="lang.typeNewCategory"></el-input>
          <el-button type="primary" size="small" @click="addNewCategory()">{{lang.addNew}}</el-button>
          <el-button type="danger" size="small" @click="resetForm()">{{lang.cancel}}</el-button>
        </div>
      </el-form-item>
      <el-form-item :label="lang.tags">
          <el-select v-model="form.tags" multiple filterable allow-create default-first-option collapse-tags :placeholder="lang.typeTags">
            <el-option v-for="i in form.tags" :key="i" :label="i" :value="i"></el-option>
          </el-select>
      </el-form-item>
      <el-form-item :label="lang.imagePost">
        <el-input v-model="form.image" :placeholder="lang.typeUrlImage"  @keydown.native="typeLinkImage"></el-input>
        <p v-if="!isShowImage">or</p>
        <el-upload 
          v-if="!this.isShowImage"
          class="avatar-uploader"
          :action="action"
          list-type="picture-card"
          :show-file-list="false"
          :on-success="onUploadSuccess"
          :before-upload="onBeforeUpload">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-image v-else 
          :src="form.image"
          :preview-src-list="[form.image]"
          class="avatar">
          <div slot="placeholder" class="image-slot"> Loading<span class="dot">...</span></div>
          <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
      </div>
        </el-image>
        <el-button v-if="isShowImage" type="primary" size="small" @click="isShowImage = false, form.image = ''" style="margin: 0 auto">clear image</el-button>
      </el-form-item>
      <!-- import Tiny components -->
      <Tiny :id="'test'" @input="input" :value="isEditPost ? form.content : ''" :action="action" :height="500"></Tiny>
      <el-button type="primary" size="small" @click="submit()" class="btn-submit">{{ isEditPost ? lang.edit : lang.addNew }}</el-button>
    </el-form>
  </div>
</template>

<script>
import Tiny from '../../../../components/Tinymce'
import ENUM from '../../../../const/enum'
import STORE_KEY from '../../../../const/storeKey'
import store from './_store'
const { ACTIONS, _POST_DETAIL } = STORE_KEY
let { ACTION, KEY_NAME } = store
export default {
  data() {
    return {
      form: {},
      isShowImage: false,
      isNewCategory: false,
      newCategory: '',
      action: ENUM.MEDIA,
      isEditPost: false
    }
  },
  components: { Tiny },
  beforeCreate(){
    let c = localStorage.getItem('_') 
    if(!(c && c == '__')) return this.$router.push('/')
  },
  created(){
    let { _id } = this.$route.params // ...posts:_id
    if(_id) {
      this.isEditPost = true
      let index = this.cacheContent.findIndex(i=> i._id == _id) 
      if( index > -1) return this.form = this.cacheContent[index]  
      else this.postJSON(ENUM.POSTS.GET_CONTENT_BY_ID, { _id }, r => {
        const { ok , data } = r 
        if(!ok || !Object.entries(data).length) return this.$router.push('/');
        let d = data
        d.tags = d.tags.split(',')
        d.category = d.categoryId
        this.form = d
        this.CHANGE_CACHE_CONTENT(r.data)
      });
    }

    if(!this.storeVue(KEY_NAME).getters.categories.length){
      this.postJSON(ENUM.POSTS.GET_CATEGORY, {}, r => {
        const {ok, data} = r
        if(!ok) return this.message('Error', 'Không lấy được danh sách Chủ đề từ server')
        this.CHANGE_CATEGORIES(data)
      })
    }
  },
  methods: {
    submit() {
      let { _id, title, category, content, image, tags } = this.form
      if(!title || !category || !content) return this.$message({
          showClose: true,
          message: 'Cảnh Báo! Nhập đầy đủ thông tin',
          type: 'warning'
        });
      let d = { 
        title,
        content,
        image: image || '',
        tags: tags.length ? tags.join() : '',
        categoryId: category
      }
      if(this.isEditPost) d._id = _id
      // check valid
      this.postJSON(ENUM.POSTS[this.isEditPost ? 'EDIT_POST' : 'CREATE_POST'], d, r => {
        const {ok} = r
        if(!ok) return this.$message({
          showClose: true,
          type: 'Error',
          message: 'Có lỗi sảy ra'
        });
        this.$router.push({ path: '/' })
        // redirect to home
      })
    },
    input(content){
      this.form.content = content
    },
    addNewCategory(){
      const {categories, newCategory} = this
      // check valid
      if(categories.some(i=> i.name == newCategory) || newCategory == '') return this.$message({
          showClose: true,
          message: 'Chủ đề đã tồn tại hoặc chưa nhập chủ đề',
          type: 'warning'
        });
      

      this.postJSON(ENUM.POSTS.CREATE_CATEGORY, {name: newCategory}, r => {
      const {ok, data} = r
      if(!ok) return this.$message({
          showClose: true,
          message: 'Có lỗi sảy ra',
          type: 'Error'
        });
        this.resetForm()
        this.CHANGE_CATEGORIES([...this.categories, data])
        this.form.category = data._id
    })
      
    },
    resetForm(){
      this.form.category = ''
      this.isNewCategory = false
      this.newCategory = ''
    },
    onUploadSuccess(response, file, fileList) {
      const { location } = response
      this.form.image = location
      this.isShowImage = true
    },
    onBeforeUpload(file) {
        if (!/^image\/(jpeg|jpg|png|gif)$/.test(file.type)) {
        this.$message({
          showClose: true,
          message: 'Chỉ chấp nhận file ảnh định dạng jpg/jpeg/png/gif ',
          type: 'warning',
          duration: 2000,
        });
        return false;
      }
      return true;
    },
    CHANGE_CATEGORIES(data){
      this.storeVue(KEY_NAME).dispatch(ACTION.CHANGE_CATEGORIES, data)
    },
    typeLinkImage(){
      setTimeout(() => this.isShowImage = !!this.form.image, 0)
    },
    CHANGE_CACHE_CONTENT(data){
      this.storeVue(_POST_DETAIL).dispatch(ACTIONS._POST_DETAIL.CHANGE_CACHE_CONTENT, data)
    },
  },
  mounted() {
  },
  computed:{
    lang(){ return this.langs() },
    categories(){
      return this.storeVue(KEY_NAME).getters.categories
    },
    cacheContent(){
      return this.storeVue(_POST_DETAIL).getters.cacheContent
    },
  }
};
</script>

<style lang="scss" scoped>
#form-post {
  width: 100%;
  max-width: 1000px;
  margin: 1rem auto;
  padding: 0 1rem;
  .el-form-item {
    max-width: 400px;
  }
  button.btn-submit {
    display: block;
    margin: 1rem auto;
  }
  .avatar {
    margin: .8rem 0 0 0;
    width: 100%;
  }
}
</style>